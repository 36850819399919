// lib
@import "@certego/certego-ui/src/styles/root";
@import "/node_modules/flag-icons/css/flag-icons.min.css";

// App.jsx
* {
  scroll-margin-top: 75px !important;
  scroll-behavior: smooth !important;
}

main {
  min-height: 85vh !important;
}

body {
  margin-top: 0;
}

section.fixed-bottom {
  margin-bottom: 7.5rem;
}

#app-toasts {
  z-index: 9999 !important;
}

// commons/

.severity,
.status-tag {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-weight: $font-weight-bold;
  height: 2rem;
  font-size: smaller;
  color: $black;

  &.sm {
    width: 1.7rem;
    font-size: smaller;
  }

  &.md {
    width: 3rem;
    font-size: $font-size-base * 1.5;
  }

  &.lg {
    width: auto;
    font-size: $font-size-base * 1.3;
  }

  &.fill-container {
    width: auto !important;
    padding: 0.6rem 0.3rem;
  }
}

// extra/

.cookiealert {
  z-index: 99999 !important;
  opacity: 1 !important;
  border-radius: 0;
  color: $white;
  background-color: $black;
  border: 1px solid $accent-2;
}

// misc/

#feedbackform-popover {
  width: 500px;
  min-width: 175px;
  min-height: 50px;
}

.horizontal-scrollable .nav-item {
  display: inline-block;
}

.notifications-popover {
  width: 500px;
  background-color: $dark;

  .nav-tabs {
    background-color: $dark;

    .nav-item {
      .nav-link.active {
        color: $info;
        border-bottom: 1px solid $info;
      }
    }
  }
}

.notifications-list {
  max-height: 400px;
  overflow-y: auto;
}

.btn-social {
  /* stylelint-disable scss/at-extend-no-missing-placeholder */
  @extend .btn;
  @extend .btn-xs;
  /* stylelint-enable scss/at-extend-no-missing-placeholder */

  background-color: $dark;
  color: $light;
  font-weight: 450;

  &:hover {
    background-color: shade-color(#1da1f2, 20%);
    color: $light !important;
  }
}

.badge-top-end-corner {
  top: -1.25em;
  right: 0.75em;
  font-size: x-small;
  position: relative;
  vertical-align: baseline;
}

/* Media queries */

@include media-breakpoint-down(md) {
  /* stylelint-disable-next-line selector-id-pattern */
  #home__bgImg {
    left: -5px !important;

    // fixed -> parallax effect
    // scroll -> normal
    background-attachment: scroll !important;
  }

  /* stylelint-disable-next-line selector-id-pattern */
  #home__mainText {
    .glitch {
      display: none;
    }
  }
}

.tooltip-inner {
  max-width: 100% !important;
}

.tooltip {
  opacity: 1 !important;
}

.bs-tooltip-auto {
  &[data-popper-placement^="right"] {
    left: 0.5em !important;
  }
}

.dropdown-divider {
  border-top: 3px solid rgba(0, 0, 0, 30%);
}

.horizontal-scrollable {
  overflow-x: auto;
  white-space: nowrap;
}

.list-group-item {
  color: white;
}

thead {
  z-index: 999;
}

input[type="file"]::file-selector-button {
  background-color: $primary;
}
